// @flow
import Cookie from 'components/footer/Cookie';
import { connect } from 'react-redux';

import type { DispatchType, StateType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/footer/Cookie';
import { setShowCookieInfo } from 'actions/appActions';

export default connect(
  (state: StateType): StateProps => ({
    showCookieInfo: state.appState.showCookieInfo,
    title: state.settingsState.title,
    cookies: state.settingsState.cookies
  }),
  (dispatch: DispatchType): DispatchProps => ({
    closeCookieInfo: (accept: boolean) => dispatch(setShowCookieInfo(false, accept))
  })
)(Cookie);
