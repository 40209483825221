// @flow
import React, { PureComponent } from 'react';

import './Cookie.css';

type OwnProps = {};
export type StateProps = {
  showCookieInfo: boolean,
  title: string,
  cookies: number
};

export type DispatchProps = {
  closeCookieInfo: (accept: boolean) => void
};

type Props = OwnProps & StateProps & DispatchProps;

const cookieEndContent =
  ", vous acceptez les CGU ainsi que l'utilisation de cookies et de technologies similaires, y compris celle" +
  ' de partenaires tiers, pour vous proposer des contenus pertinents et des publicités ciblées en fonction' +
  " de vos centres 'intérêts.";

class Cookie extends PureComponent<Props> {
  acceptCookies = () => this.props.closeCookieInfo(true);

  refuseCookies = () => this.props.closeCookieInfo(false);


  render() {
    const { title, showCookieInfo } = this.props;
    if (!showCookieInfo) {
      return null;
    }

    return (
      <div className="cookie-container">
        <div className="box cookie">
          <p>
            En poursuivant votre navigation sur{' '}
            {title}
            {cookieEndContent}
            <br />
            <a href="https://www.ffr.fr/cgu" target="_blank" rel="noopener noreferrer" title={`Consulter les Conditions générales d’utilisation de la Fédération Française de Rugby`}>
              Afficher les CGUs
            </a>
          </p>

          <div className="btn-container">
            <button onClick={this.acceptCookies} className="btn btn--primary btn--full">Accepter les cookies</button>
            <button onClick={this.refuseCookies} className="btn btn--primary btn--full">Refuser</button>
          </div>
        </div>

        <button className="btn cookie-close" onClick={this.refuseCookies}>
          Continuer sans accepter &#10142;
        </button>
      </div>
    );
  }
}

export default Cookie;
